import '@fortawesome/fontawesome-free/css/all.css';
import 'webf';
import 'webf/dist/webf.bundle.css';

import '../styles/bootstrap_extension.scss';
import '../styles/bootstrap_pull.css';

import '../styles/main.scss';
import '../styles/smart.scss';
import '../styles/smart.webf.scss';

import refreshWebf from '../js/app/smart/refresh_webf.js'

$(function() {
    refreshWebf();
});

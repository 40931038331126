import $ from "jquery";

export default function refreshWebf() {
    /*************\
        Webf UI
     /*************/

    /**
     * Checkboxes
     */
    $('.smart-input-checkbox').webfCheckbox({
        classWrapper: 'smart-wrap-checkbox',
    });

    /**
     * Boutons radio
     */
    $('.smart-input-radio').webfRadio({
        classWrapper: 'smart-wrap-radio',
    });

    /**
     * Tooltips de un ou 2 mots
     */
    $('.smart-help').webfTooltip({
        showMonthYear: true,
        format: 'dd/MM/yyyy HH:mm',
        tooltipClass: 'smart-tooltip tooltip-up',
        position: {
            my: 'center bottom',
            at: 'center top',
            collision: 'fit none',
            offset: {
                top: -5,
            },
        },
        zIndex: '2000',
        animation: 'fading',
        delayOpen: 500,
        delayClose: 130,
    });

    /**
     * Dates
     */
    $('.smart-datetime').webfDatetimepicker({
        showMonthYear: true,
        format: 'dd/MM/yyyy HH:mm',
        onSelectDate: function () {
            $(this).webfDatetimepicker('close');
            $(this).trigger('change');
        },
        buttons: {
            'Sans date': {
                cls: 'small primary',
                click: function () {
                    $(this).val('');
                    $(this).webfDatetimepicker('close');
                    $(this).trigger('change');
                },
            },
        },
    });

    $('.smart-date').webfDatetimepicker({
        showMonthYear: true,
        yearRange: 'c-60:c+60',
        format: 'dd/MM/yyyy',
        timepicker: false,
        onSelectDate: function () {
            $(this).webfDatetimepicker('close');
            $(this).trigger('change');
        },
        buttons: {
            'Sans date': {
                cls: 'small primary',
                click: function () {
                    $(this).val('');
                    $(this).webfDatetimepicker('close');
                    $(this).trigger('change');
                },
            },
        },
    });

    /***********************************\
        Boxes cliquables pour déplier
     \***********************************/
    $(document).on(
        'click',
        '.webf-button.drop, .webf-box > .box-header.drop',
        function (ev) {
            var $webfBox = $(this).closest('.webf-box'),
                $iIcon = $(this).children('i.webf');

            if ($webfBox.hasClass('fold')) {
                $webfBox.children('.box-body').slideDown(400, function (ev) {
                    $webfBox.removeClass('fold');
                    $iIcon
                        .removeClass('webf-icon-caret-down')
                        .addClass('webf-icon-caret-up');
                });
            } else {
                $webfBox.children('.box-body').slideUp(400, function (ev) {
                    $webfBox.addClass('fold');
                    $iIcon
                        .removeClass('webf-icon-caret-up')
                        .addClass('webf-icon-caret-down');
                });
            }
        }
    );

    /*************************\
        Boites de dialogue
     \*************************/
    $('.dialog-wait').webfDialog({
        autoOpen: false,
        zIndex: 1200,
        closeOnEscape: false,
        box: false,
        dialogClass: 'dialog-loading',
    });
}
